<template>
  <div class="warp">
    <div class="infoBox">
      <div class="inner-content">
        <!--商品相册-->
        <template v-if="params.goods_id">
          <goods-zoom v-if="goods.goods_gallery_list" :images="goods.goods_gallery_list" :spec-img="specImage" />
        </template>
        <template v-else>
          <goods-zoom v-if="goods.img_list" :images="goods.img_list" :spec-img="specImage" />
        </template>
        <!--商品信息【包括规格、优惠券、促销等】-->
        <!-- <goods-info :goods="goods" @spec-img-change="(img) => { this.specImage = img }"/> -->
      </div>
      <div class="goods-info">
        <div class="mouse-cover-box"></div>
        <h2 v-if="params.goods_id" v-html="goods.goods_name"></h2>
        <h2 v-else v-html="goods.name"></h2>
        <div class="priceBox">
          <template>
            <p v-if="this.$route.name == 'goodsAudit'">成本价：{{ goods.cost | unitPrice('￥') }}</p>
            <p v-else>销售价：{{ goods.price | unitPrice('￥') }}</p>
            <p v-if="this.$route.name == 'goodsAudit'">毛利率：{{ formData.platform_profit || 0 }}%</p>
            <p v-else>利润率：{{ formData.managerProfit || 0 }}%</p>
            <p>市场价：{{ goods.mktprice | unitPrice('￥') }}</p>
          </template>
        </div>
        <h3>
          商品编号：{{ formData.good_sn }}
        </h3>
        <h3>
          来源：{{ goods.goods_source == 1 ? '京东' : (goods.self_operated == 1 ? "自有" : "平台方") }}
        </h3>
        <h3 v-if="params.goods_id && goods.quantity">
          库存：{{ goods.quantity ? goods.quantity : '0' }}
        </h3>
        <h3 v-else>库存：{{ goods.sale_state === 1 ? '有' : '无' }}</h3>
      </div>
    </div>

    <div class="goodBottom">
      <div class="left">
        <h4>商品详情</h4>
        <div class="intro" v-if="params.goods_id" v-html="goods.intro"></div>
        <template v-else>
          <div class="intro" v-html="goods.param"></div>
          <div class="intro" v-html="goods.introduction"></div>
        </template>
      </div>
      <!-- <div class="right">
        <h4>专题推荐</h4>
      </div>-->
    </div>


  </div>
</template>

<script>
import * as API_goods from '@/api/goods';
import { CategoryPicker } from '@/components';
import goodsZoom from './-goods-zoom.vue';

export default {
  name: 'good',
  components: {
    [CategoryPicker.name]: CategoryPicker,
    goodsZoom
  },
  props: {
    formData: {
      from: "",
      goods_id: '',
      good_sn: "",
      managerProfit: "",
      platform_profit: ""
    }
  },
  data () {
    return {
      isFirm: true,
      specImage: '',
      tabBaseUrl: '',
      dialogShopShow: false,
      dialogShopShow1: false,
      aaaaa: '',
      idsList: [],
      categoryListLevel1: [],
      /** 列表loading状态 */
      loading: false,
      goods: {

      },
      id: '',
      pa: {
        set_under_message: ''
      },
      params: {
        type: ''
      },
      PTdepth: 3,
      goods_source: 0,
      tagShow: false,
      firstValue: '',
      secondValue: '',
      thirdValue: '',
      firstData: [],
      secondData: [],
      thirdData: [],
    };
  },
  created () {
    if (this.formData.from === '1') {
      this.tabBaseUrl = '/shop-goods/goods';
    } else {
      this.tabBaseUrl = '/markets/privateGoods';
    }
  },
  watch: {
    formData (newval, oldval) {
      this.params = {
        ...this.params,
        ...this.formData
      };
      this.GET_GoodsList();
    }
  },
  mounted () {
    this.params = {
      ...this.params,
      ...this.formData
    };
    this.GET_GoodsList();
  },
  methods: {
    res () {
      this.dialogShopShow = true;
      this.dialogShopShow1 = false;
    },
    hov (item, index) {
      if (index === 0) {
        this.child = item.children;
        this.child1 = [];
      }
      if (index === 1) {
        this.child1 = item.children;
      }
      if (index === 'all') {
        this.child = [];
        this.child1 = [];
      }
    },
    GET_GoodsList () {
      console.log(this.params);
      if (this.params.goods_id) {
        API_goods.getGoodData(this.params.goods_id, {}).then(response => {
          this.id = this.params.goods_id;
          response.intro.replace(/http:/g, '');
          this.goods = response;
          if (this.goods.intro) {
            this.goods.intro = this.goods.intro.replace(/http:/g, '');
          }
          if (this.goods.introduction) {
            this.goods.introduction = this.goods.introduction.replace(/http:/g, '');
          }
        });
      }
    }
  }
};
</script>
<style type="text/scss" lang="scss" scoped>
@import "./styles";
</style>
<style lang="scss" scoped>
.mouse-cover-box {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 31px;
  left: -25px;
  display: none;
}

/deep/ div.toolbar {
  height: 80px;
  padding: 20px 0;
}

/deep/ .el-table {
  width: 100%;
  overflow-x: scroll;

  & td:not(.is-left) {
    text-align: center;
  }
}

.inner-toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/deep/ .pop-sku {
  .toolbar {
    display: none;
  }

  .el-dialog__body {
    .el-table {
      border: 1px solid #e5e5e5;
    }
  }

  .el-table__body-wrapper {
    max-height: 400px;
    overflow-y: scroll;
  }
}

.toolbar-search {
  margin-right: 10px;
  width: 20%;
}

div.toolbar-btns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;

  div {
    span {
      display: inline-block;
      font-size: 14px;
      color: #606266;
    }
  }

  .conditions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    min-width: 24.5%;

    .choose-machine {
      width: 60%;
    }
  }
}

.goods-image {
  width: 40px;
  height: 40px;
}

/deep/ div.cell {
  overflow: hidden;

  text-overflow: ellipsis;

  display: -webkit-box;

  -webkit-box-orient: vertical;

  -webkit-line-clamp: 2;
}

/*下架原因*/
.under-reason {
  color: red;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
.warp {
  border-radius: 5px;
  background: #fff;
  width: 100%;
  margin: auto;
  height: 510px;
  overflow-y: auto;
}

.search {
  padding-top: 20px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.sInput {
  width: 400px;
  margin-bottom: 20px;
}

.searchBottom {
  text-align: left;
}

.flBox {
  position: relative;
  display: inline-block;
}

.link {
  display: inline-block;
}

.link span {
  margin-left: 30px;
  cursor: pointer;
}

.flBox .menu {
  width: 200px;
  height: 48px;
  line-height: 48px;
  background: rgba(26, 67, 169, 1);
  text-align: center;
  color: #fff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.flBox ul {
  display: none;
  list-style: none;
  position: absolute;
  left: 0;
  width: 200px;
  top: 48px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
  margin: 0;
  padding: 0;
  padding: 10px 0;
  height: 300px;
  overflow: auto;
}

.flBox ul li:hover {
  color: rgba(26, 67, 169, 1);
  cursor: pointer;
}

.flBox ul li {
  display: block;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #fff;
}

.flBox:hover ul {
  display: block;
}

.flBox ul:hover {
  display: block;
}

.px {
  width: 300px;
}

.px span {
  padding-right: 12px;
  margin-right: 10px;
  background: url("../../../../../assets/ac1.png") no-repeat right center;
  -webkit-background-size: 8px auto;
  background-size: 8px auto;
  cursor: pointer;
}

.px span.active.up {
  background-image: url("../../../../../assets/ac2.png");
}

.px span.active.down {
  background-image: url("../../../../../assets/ac3.png");
}

div.toolbar-btns {
  display: block;
}

div.toolbar-btns .conditions {
  display: inline-block;
  width: auto;
  min-width: 0;
  margin-right: 30px;
}

.inner-content {
  padding-top: 30px;
  float: left;
}

.warp {
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.intro>>>img {
  display: block;
  width: 100%;
}

.intro>>>p {
  margin: 0;
}

.goodBottom {
  overflow: hidden;
  margin-top: 20px;
}

.goodBottom h4 {
  margin: 0;
  padding-left: 20px;
  line-height: 50px;
  background: #eee;
}

.goodBottom .left {
  float: left;
  width: 840px;
  border: 1px solid #ddd;
}

/* .goodBottom .right {
  width: 300px;
  float: right;
  border: 1px solid #ddd;
} */
.goods-info {
  position: relative;
  width: 520px;
  float: left;
  margin-left: 30px;
}

.goods-info h2 {
  font-weight: 500;
  line-height: 60px;
  font-size: 16px;
}

.goods-info h3 {
  font-weight: 500;
  line-height: 27px;
  font-size: 14px;
  margin: 0;
  padding-left: 20px;
}

.goods-info .priceBox {
  background: #f3f3f3;
  padding: 0 20px;
  border: 1px solid #eee;
}

.goods-info .priceBox p {
  color: #999;
  font-size: 14px;
}

.goods-info .priceBox p:first-child {
  font-size: 15px;
  color: #000;
}

.goods-info .priceBox p:first-child span {
  color: #ff3e3e;
}

.infoBox {
  overflow: hidden;
}
</style>




















