export interface XTableStdResponse {
  page_size: number;
  page_no: number;
  data: any[];
  data_total: number;
}

export interface XTableStdRequestParams {
  pageIndex: number;
  pageSize: number;
  search: { [key: string]: any };
  sort: { [key: string]: any };
}

export interface XTableFormValidate {
  type?: 'string' | 'number' | 'boolean' | 'method' | 'regexp' | 'integer' | 'float' | 'array' | 'object' | 'enum' | 'date' | 'url' | 'hex' | 'email' | 'any';
  required?: boolean;
  message?: string;
  trigger?: ('blur' | 'change' | 'input')[];
  validator?: (rule, value, callback: (err?: Error) => void) => void;
  pattern?: RegExp;
  mix?: number;
  max?: number;
  len?: number;
  enum?: string[];
  whitespace?: boolean;
  transform?: (value: any) => any;
  fields?: { [key: string]: XTableFormValidate };
}

export interface XTableSearchFormConfig {
  formData(): { [key: string]: any };

  rules?(ctx: XTableSearchProxy): { [key: string]: XTableFormValidate[] };

  onSortChange?(parentSort: { [key: string]: any }, prop: string, order: 'ascending' | 'descending', column?: any): { [key: string]: any };
}

export interface XTableBasicConfig {
  data?: any[];
  pageSize?: number;
  pageIndex?: number;
}

export interface XTableSelectConfig {
  isAllSelectedMode?: boolean;
  selectedData?: any[];
  unselectedData?: any[];
  checkRowSelectable?: (row, index) => boolean;
}

export interface XTableConfig {
  key: string; // 必传，列表数据中判断唯一性的字段名
  tablePageSizes?: number[];
  tableLayout?: ('total' | 'sizes' | 'prev' | 'pager' | 'next' | 'jumper')[];
  basic?: XTableBasicConfig;
  select?: XTableSelectConfig;
  search?: XTableSearchFormConfig;

  onRequest?(params: XTableStdRequestParams, localData?: any[]): Promise<XTableStdResponse>;
}

export class XTableSelectProxy {
  master?(): XTableProxy;

  isAllSelectedMode = false;
  selectedData: any[] = [];
  unselectedData: any[] = [];
  // key索引表，用来加快检索速度
  selectedDataKeyMap: any = {};
  unselectedDataKeyMap: any = {};
  checkRowSelectable?: (row, index) => boolean;

  constructor(master: XTableProxy) {
    this.master = () => master;
  }

  getSelectStatus(row) {
    if (this.isAllSelectedMode) {
      return row.$selected === undefined ? true : row.$selected;
    } else {
      return row.$selected;
    }
  }

  selectRow(row) {
    const {
      key,
      isLocalMode,
    } = this.master!();

    if (this.isAllSelectedMode) {
      // 如果是全选模式，则默认全部选中
      // 此时点击的数据，都会进入未被选择的数据中
      if (row.$selected && !this.unselectedDataKeyMap[row[key]]) {
        this.unselectedData = this.unselectedData.filter(r => r[key] !== row[key]);
      } else {
        this.unselectedData.push(row);
      }
    }

    if (isLocalMode || !this.isAllSelectedMode) {
      if (row.$selected && !this.selectedDataKeyMap[row[key]]) {
        this.selectedData.push(row);
      } else {
        this.selectedData = this.selectedData.filter(r => r[key] !== row[key]);
      }
    }

    this.unselectedDataKeyMap[row[key]] = !row.$selected;
    this.selectedDataKeyMap[row[key]] = row.$selected;
  }

  toggleAllSelect() {
    const isAllSelectedMode = !this.isAllSelectedMode;
    this.reset(null!, true);
    this.isAllSelectedMode = isAllSelectedMode;

    const {
      data,
      refreshPage
    } = this.master!();
    data.forEach(d => d.$selected = isAllSelectedMode);
    refreshPage && refreshPage();
  }

  reset(select?: XTableSelectConfig, skipRefreshPage = false) {
    console.log(111)
    const {
      data,
      key,
      refreshPage,
    } = this.master!();

    if (select) {
      data.forEach(d => d.$selected = false);

      this.isAllSelectedMode = select.isAllSelectedMode!;
      this.selectedData = select.selectedData || this.selectedData;
      this.unselectedData = select.unselectedData || this.unselectedData;
      this.checkRowSelectable = select.checkRowSelectable;

      this.selectedData.forEach(d => {
        this.selectedDataKeyMap[d[key]] = true;
      });

      this.unselectedData.forEach(d => {
        this.unselectedDataKeyMap[d[key]] = true;
      });

      // 将预设的已选未选传入数据中
      data.forEach(d => {
        if (this.selectedDataKeyMap[d[key]] !== undefined) {
          d.$selected = true;
        }

        if (this.unselectedData[d[key]] !== undefined) {
          d.$selected = false;
        }
      });
    } else {
      this.isAllSelectedMode = false;
      this.selectedData = [];
      this.selectedDataKeyMap = {};
      this.unselectedData = [];
      this.unselectedDataKeyMap = {};
    }

    !skipRefreshPage && refreshPage && refreshPage();
  }
}

export class XTableSearchProxy {
  master?(): XTableProxy;

  search!: XTableSearchFormConfig;
  sort: { [key: string]: any } = {};
  formData: { [key: string]: any } = {};
  rules: { [key: string]: XTableFormValidate[] } = {};

  constructor(master: XTableProxy) {
    this.master = () => master;
  }

  reset(search?: XTableSearchFormConfig) {
    if (search) this.search = search;
    if (!this.search) return;
    this.rules = this.search.rules ? this.search.rules(this) : {};
    this.formData = this.search.formData();
    this.sort = {};
  }

  onSortChange(column: any, prop: string, order: any) {
    this.sort = this.search.onSortChange ? this.search.onSortChange(this.sort, prop, order, column) : {};
  }
}

export class XTableProxy {
  data: any[] = [];
  pageIndex = 1;
  pageSize = 20;
  count = 0;
  select = new XTableSelectProxy(this);
  search = new XTableSearchProxy(this);
  isLocalMode = false;
  tablePageSizes = [10, 20, 50, 100];
  tableLayout = ['total', 'sizes', 'prev', 'pager', 'next', 'jumper'];

  get key() {
    return this.config.key;
  }

  get layout() {
    return this.tableLayout.join(',');
  }

  constructor(private config: XTableConfig) {
    this.reset();
  }

  reset(skipRequest = false) {
    const {config} = this;

    if (config.tablePageSizes) {
      this.tablePageSizes = config.tablePageSizes;
    }

    if (this.config.tableLayout) {
      this.tableLayout = Array.from(new Set(config.tableLayout));
    }

    if (!config.onRequest) {
      this.isLocalMode = true;
      // 如果没有传入请求方法，则默认本地分页模式
      config.onRequest = (params, localData) => {
        const startIndex = (params.pageIndex - 1) * params.pageSize;
        let endIndex = startIndex + params.pageSize;
        // 避免越界
        if (endIndex > localData!.length) endIndex = localData!.length;
        return Promise.resolve({
          page_no: params.pageIndex,
          page_size: params.pageSize,
          data: localData!.slice(startIndex, endIndex),
          data_total: localData!.length,
        });
      };
    }

    if (config.basic) {
      this.data = config.basic.data || [];
      this.pageIndex = config.basic.pageIndex || 1;
      this.pageSize = config.basic.pageSize || 10;
    } else {
      this.data = []
      this.pageIndex = 1;
      this.pageSize = 20;
    }
    console.log(config.select)
    this.select.reset(config.select, true);
    this.search.reset(config.search);

    if (skipRequest) {
      return Promise.resolve();
    }

    return this.request();
  }

  exportSelectedData(force = false) {
    if (force && this.select.isAllSelectedMode) {
      const {key} = this.config;
      const unselectDataKeys = this.select.unselectedData.map(item => item[key]);
      return this.data.filter(d => !unselectDataKeys.includes(d[key]));
    }
    // 避免外部弄脏数据
    return [...this.select.selectedData];
  }

  exportUnselectedData() {
    // 避免外部弄脏数据
    return [...this.select.unselectedData];
  }

  /**
   * 当全选模式开启后，导出的数据为未选择的数据
   * 否则，导出的数据为选择的数据
   */
  exportData() {
    if (this.select.isAllSelectedMode) {
      return this.exportUnselectedData();
    } else {
      return this.exportSelectedData();
    }
  }

  request(restart = false) {
    if (restart) {
      this.pageIndex = 1;
    }

    this.loading && this.loading();
    return this.config.onRequest!({
      pageIndex: this.pageIndex,
      pageSize: this.pageSize,
      search: this.search.formData || {},
      sort: this.search.sort || {},
    }, this.config.basic?.data || []).then(resp => {
      if (!resp) return;

      resp.data = resp.data.map(d => {
        if (this.select.isAllSelectedMode) {
          if (!this.select.unselectedDataKeyMap[d[this.key]]) {
            d.$selected = true;
          }
        } else {
          if (this.select.selectedDataKeyMap[d[this.key]]) {
            d.$selected = true;
          }
        }
        if (d.name) {
          d.name = d.name.replace('&lt;', '<').replace('&gt;', '>').replace('&amp;', '&')
        } else if (d.goods_name) {
          d.goods_name = d.goods_name.replace('&lt;', '<').replace('&gt;', '>').replace('&amp;', '&')
        }
        return d;
      });

      this.data = resp.data;
      this.count = resp.data_total;

      this.refreshPage && this.refreshPage();
    }, err => {
      this.refreshPage && this.refreshPage();
    });
  }

  refreshPage?();

  rebuildLayout?();

  loading?();

  clearSort?()

  _loading = false;

  get isLoading() {
    return this._loading;
  }
}

export const $xTable = {
  create(config: XTableConfig) {
    return new XTableProxy(config);
  }
}
