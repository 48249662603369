/**
 * 商品管理API
 */

import request from '@/utils/request'

/**
 * 获取商品列表
 * @param params
 */
export function getGoodsList(params) {
	return request({
		url: 'admin/goods',
		method: 'get',
		loading: false,
		params
	})
}
//批量下架

/**
 * 搜索单个商品信息 商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodData(ids, params) {
	return request({
		url: `admin/goods/comboGoods/${ids}`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 搜索单个商品信息 商品审核
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodDataAudit(ids, params) {
	return request({
		url: `admin/goods/${ids}`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 搜索库存商品数据  搜索商品sku（规格）信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsStockList(ids, params) {
	return request({
		url: `admin/goods/${ids}/skus`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 编辑商品   搜索商品参数，获取所选分类关联的参数信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getEditGoodsParams(category_id, goods_id) {
	return request({
		url: `admin/goods/categories/${category_id}/${goods_id}/params`,
		method: 'get',
		loading: false
	})
}

/**
 * 发布商品   搜索商品参数，获取所选分类关联的参数信息
 * @param category_id
 * @returns {Promise<any>}
 */
export function getGoodsParams(category_id) {
	return request({
		url: `admin/goods/category/${category_id}/params`,
		method: 'get',
		loading: false
	})
}



/**
 * 批量审核商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function batchAuditGoods(ids, params) {
	return request({
		url: `admin/goods/${ids}/batchAuth`,
		method: 'put',
		data: params
	})
}


/**
 * 搜索积分专区表列表
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getExchangeArea(params) {
	return request({
		url: `/admin/goods/exchangeArea`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 搜索单个商品信息 商品列表 编辑
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodBindData(ids, params) {
	return request({
		url: `admin/goods/comboGoods/bind/${ids}`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 获取积分商品分类列表
 * @param id
 */
export function getExchangeCatsList(id) {
	return request({
		url: `admin/goods/comboGoods/exchange-cats/${id}/children`,
		method: 'get',
		loading: false
	})
}

/**
 * 商品列表 删除商品  大平台删除商品
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function deletePartyGoods(ids) {
	return request({
		url: `admin/goods/${ids}/batchDelete`,
		method: 'put',
	})
}

/**
 * 商品列表 删除商品  商家将商品放入回收站  下架的商品才能放入回收站
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function deleteGoods(ids, params) {
	const _params = {}
	return request({
		url: `admin/goods/${ids}/recycle`,
		method: 'put',
		data: _params
	})
}


/**
 * 上架正常商品 商品相关API-添加商品
 * @param params
 * @returns {Promise<any>}
 */
export function aboveGoods(params) {
	return request({
		url: 'admin/goods/comboGoods',
		method: 'post',
		data: params,
		headers: { 'Content-Type': 'application/json' }
	})
}

/**
 * 添加积分专区表
 * @param params
 * @returns {Promise<any>}
 */
export function addExchangeArea(params) {
	return request({
		url: 'admin/goods/exchangeArea',
		method: 'post',
		data: params,
		// headers: { 'Content-Type': 'application/json' }
	})
}

/**
 * 修改积分专区表
 * @param goods_id
 * @param params
 * @returns {*}
 */
export function SetExchangeArea(id, params) {
	return request({
		url: `admin/goods/exchangeArea/${id}`,
		method: 'put',
		data: params
	})
}

/**
 * 删除积分专区表
 * @param goods_id
 * @param params
 * @returns {*}
 */
export function editAreaStatus(id, params) {
	return request({
		url: `admin/goods/exchangeArea/editStatus/${id}`,
		method: 'put',
		data: params
	})
}

/**
 * 品牌列表 ids为categoryid  商品发布，获取所选分类关联的品牌信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsBrandList(ids, params) {
	return request({
		url: `admin/goods/comboGoods/category/${ids}/brands`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 品牌列表 ids为categoryid  商品发布，获取所选分类关联的品牌信息
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getGoodsBrandListAudit(ids, params) {
	return request({
		url: `admin/goods/categories/${ids}/brands`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 运费模板列表
 * @param ids
 * @param params
 * @returns {Promise<any>}
 */
export function getTplList(ids, params) {
	return request({
		url: `admin/goods/${ids}/ship_template_list`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 删除扶贫商品
 * @param id
 */
// export function deleteGoods(ids, params) {
// 	return request({
// 		url: `admin/goods/comboGoods/${ids}`,
// 		method: 'delete',
// 		loading: false,
// 		data: params
// 	})
// }


/**
 * 下架商品
 * @param goods_id 商品ID
 * @param reason 下架原因
 * @returns {*}
 */
export function underGoods(goods_ids, reason) {
	return request({
		url: `admin/goods/${goods_ids}/under`,
		method: 'put',
		data: { reason }
	})
}

/**
 * 上架商品
 * @param goods_id 商品ID
 * @returns {*}
 */
export function upGoods(goods_ids) {
	return request({
		url: `admin/goods/${goods_ids}/up`,
		method: 'put'
	})
}

/**
 * 获取待审核商品列表
 * @param params
 */
export function getAuditGoods(params) {
	params.is_auth = 0
	return request({
		url: `admin/goods`,
		method: 'get',
		loading: false,
		params
	})
}

/**
 * 审核商品
 * @param goods_id
 * @param params
 * @returns {*}
 */
export function auditGoods(goods_id, params) {
	return request({
		url: `admin/goods/${goods_id}/auth`,
		method: 'put',
		data: params
	})
}

/**
 * 商品索引生成
 */
export function initSearchIndex() {
	return request({
		url: 'admin/goods/search',
		method: 'get'
	})
}

/**
 * 店铺商品索引生成
 */
export function initSearchShopgroods() {
	return request({
		url: 'admin/goods/search/shopgroods',
		method: 'get'
	})
}

/**
 * 获取商品设置
 */
export function getGoodsSettings() {
	return request({
		url: 'admin/goods/settings',
		method: 'get'
	})
}

/**
 * 修改商品设置
 * @param params
 */
export function editGoodsSettings(params) {
	return request({
		url: 'admin/goods/settings',
		method: 'post',
		data: params
	})
}


/**
 * 修改正常商品 编辑商品时用到
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function editGoods(id, params) {
	return request({
		url: `admin/goods/comboGoods/${id}`,
		method: 'put',
		data: params,
		headers: { 'Content-Type': 'application/json' }
	})
}
//变更记录备份
// export function getChange(params) {
// 	return request({
// 		url: '/admin/goods/change/log',
// 		method: 'get',
// 		data:params
// 	})
// }
//查看变更记录
export function getChange(params) {
	return request({
		url: '/admin/goods/change/log',
		method: 'get',
		params
	})
}

export function chnagePrice(goods_id, params) {
	return request({
		url: `admin/goods/chnage/price/${goods_id}`,
		method: 'put',
		data: params
		// headers: { 'Content-Type': 'application/json' }
	})
}

/**
 * 批量改价
 * @param id
 * @param params
 * @returns {Promise<any>}
 */
export function batchChangePrice(goods_id, params) {
	return request({
		url: `admin/goods/batchChangePrice/${goods_id}`,
		method: 'put',
		params
	})
}

//获取供应商列表
export function getListShopNameAndId(params) {
	return request({
		url: `/admin/shops/listShopNameAndId`,
		method: 'get',
		params
	})
}

// 检测是否存在改价任务
export function hasReviseTask() {
	return request({
		url: '/admin/goods/cheack',
		method: 'get',
		loading: false,
	})
}

/**
 * 获取商品改价记录列表
 * @param params
 */
export function getGoodsPriceChange(params) {
  return request({
    url: 'admin/goods/managerGoodsPriceChangeLog/page',
    method: 'get',
    params
  })
}
/**
 * 获取单个商品的详情
 * @param {*} goods_id 
 * @returns 
 */
export function getGoodsDetail (goods_id) {
  return request({
    url:"/admin/goods/getGoodsDetail/" + goods_id,
    method: "get",
  })
}